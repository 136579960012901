// Excerpts from https://github.com/JulietaUla/Montserrat/blob/cbe1ffc710fb25488f8cf505d62e677f776a3c34/fonts/webfonts/Montserrat.css

/** Montserrat ExtraLight **/
@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    font-style: normal;
    font-display: swap;
    src: url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-ExtraLight.eot");
    src: url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-ExtraLight.eot?#iefix") format('embedded-opentype'),
         url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-ExtraLight.woff2") format("woff2"),
         url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-ExtraLight.woff") format("woff");
}

/** Montserrat ExtraLight-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    font-style: italic;
    font-display: swap;
    src: url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-ExtraLightItalic.eot");
    src: url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-ExtraLightItalic.eot?#iefix") format('embedded-opentype'),
         url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-ExtraLightItalic.woff2") format("woff2"),
         url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-ExtraLightItalic.woff") format("woff");
}

/** Montserrat Light **/
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-Light.eot");
    src: url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-Light.eot?#iefix") format('embedded-opentype'),
         url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-Light.woff2") format("woff2"),
         url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-Light.woff") format("woff");
}

/** Montserrat Light-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    src: url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-LightItalic.eot");
    src: url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-LightItalic.eot?#iefix") format('embedded-opentype'),
         url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-LightItalic.woff2") format("woff2"),
         url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-LightItalic.woff") format("woff");
}

/** Montserrat Bold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-Bold.eot");
    src: url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-Bold.eot?#iefix") format('embedded-opentype'),
         url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-Bold.woff2") format("woff2"),
         url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-Bold.woff") format("woff");
}

/** Montserrat Bold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: italic;
    font-display: swap;
    src: url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-BoldItalic.eot");
    src: url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-BoldItalic.eot?#iefix") format('embedded-opentype'),
         url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-BoldItalic.woff2") format("woff2"),
         url("/assets/fonts/Montserrat/fonts/webfonts/Montserrat-BoldItalic.woff") format("woff");
}
