@import "Montserrat";
@import "variables";
@import "bootstrap/scss/bootstrap";

//$backgroundColor: #ffffff;
//$bodyColor: #000000;
//$bodyFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
//
//body {
//  background: $backgroundColor;
//  color: $bodyColor;
//  font-family: $bodyFont;
//}

#main-nav {
//  padding-top: $spacer * 2;
  padding-bottom: $spacer * 2;
  @include media-breakpoint-down(md) {
//    padding-top: $spacer * 1;
    padding-bottom: $spacer * 1;
  }
  .container {
    @include media-breakpoint-down(md) {
      padding-bottom: $spacer * 0.5;
    }
  }
  .container {
    border-bottom: $card-border-width solid $card-border-color;
  }
  .navbar-brand h1 {
    margin: 0 !important;
    padding: 0 !important;
  }
}

#cookie-consent {
  position: -webkit-sticky;
  position: sticky;
  bottom: -$border-width; // hide bottom border until scroll to bottom
  width: 100%;
  p {
    line-height: 1.25;
  }
}

html {
  position: relative;
  min-height: 100%;
}
//@media
//only screen and (-webkit-min-device-pixel-ratio: 2),
//only screen and (   min--moz-device-pixel-ratio: 2),
//only screen and (     -o-min-device-pixel-ratio: 2/1),
//only screen and (        min-device-pixel-ratio: 2),
//only screen and (                min-resolution: 192dpi),
//only screen and (                min-resolution: 2dppx) { 
//  html {
//    min-height: 50%;
//  }
//}
//@media
//only screen and (-webkit-min-device-pixel-ratio: 3),
//only screen and (   min--moz-device-pixel-ratio: 3),
//only screen and (     -o-min-device-pixel-ratio: 3/1),
//only screen and (        min-device-pixel-ratio: 3),
//only screen and (                min-resolution: 288dpi),
//only screen and (                min-resolution: 3dppx) { 
//  html {
//    min-height: 33.33%;
//  }
//}
//@media
//only screen and (-webkit-min-device-pixel-ratio: 4),
//only screen and (   min--moz-device-pixel-ratio: 4),
//only screen and (     -o-min-device-pixel-ratio: 4/1),
//only screen and (        min-device-pixel-ratio: 4),
//only screen and (                min-resolution: 384dpi),
//only screen and (                min-resolution: 4dppx) { 
//  html {
//    min-height: 25%;
//  }
//}

body.has-footer {
  /* Margin bottom by footer height */
  margin-bottom: 112px;
}

.container.has-footer {
  padding-bottom: 15px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 112px;
  line-height: 86px;
  //background-color: #f5f5f5;
  > .container {
    padding-right: 15px;
    padding-left: 15px;
    height: auto;
  }
  #footer-nav {
    height: auto;
    #footer-nav-right-text {
      line-height: 2rem;
    }
  }
}

//.work .card-columns {
.work .work-columns {
//  @include media-breakpoint-down(sm) {
//    column-count: 1 !important;
//  }
//  @include media-breakpoint-up(md) {
//    column-count: 2 !important;
//  }
//  @include media-breakpoint-up(xl) {
//    column-count: 3 !important;
//  }
  .card {
    border-width: 0 0 $card-border-width 0;
    border-radius: 0 !important;
    .card-img-top {
      @include border-radius($card-border-radius);
    }
    .card-body {
      //padding-top: $card-spacer-y / 2 !important;
      .card-title {
        margin-bottom: .25rem !important;
      }
    }
    a {
      color: inherit !important;
    }
  }
}

.work-gallery-nav {
  &.active {
    background-color: $gray-400 !important;
  }
  .work-gallery-nav-thumb {
    max-height: 4rem;
  }
}
.work-gallery-content {
  max-height: calc(100vh - 20px) !important;
}
.work-gallery-content-image {
  //width: 100%;
}

.overlay-play {
 position: relative; 
  &::before {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "\25B6";
    font-size:  4rem;
    color: $gray-100;
    opacity: 0.8;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  }
  &.active::before {
    color: $white;
    opacity: 1;
  }
}
.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px; height: 0; overflow: hidden;
  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 20px);
  }
}

a[target="_blank"]:not(.hide-external) {
  margin-right: 17px;
  &::after {
    content: '\29C9';
    margin: -4px 3px 3px 2px;
    transform: rotate(360deg) scaleX(-1);
    position: absolute;
  }
}
